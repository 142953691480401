<template>
    <section>
        <div class="bkgWrap">
            <ul class="text-inverse">
                <li class="text-inverse" v-for="s in project.skills" :key="s">{{ s }}</li>
            </ul>
        </div>
        <div class="bkgWrap">
            <h3 class="text-inverse">{{ project.name }}</h3>
        </div>
        <div class="bkgWrap">
            <p class="text-inverse">{{ project.description }}</p>
        </div>
        <div class="bkgWrap2" v-if="project.caseStudy">
            <button class="text-inverse" @click.stop="$emit('open-project', project)">Case Study</button>
        </div>
        <div class="bkgWrap2" v-if="project.link">
            <button class="text-inverse" @click.stop="openLink">Visit Project</button>
        </div>
    </section>
</template>

<script>
    export default {
        name: "InfoOverlay",
        props: {
            project: Object
        },
        data: function() {
            return {
                // myVariableName: 'This is a function to save unique state'
            }
        },
        methods: {
            openLink: function(){
                window.open(this.project.link, '_blank');
            }
        },
    }
</script>

<style scoped>
    section{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    p, h3, ul, button{
        position: relative;
        padding: 1.5rem;
        width: max-content;
        z-index: 1;
        line-height: 10%;
        text-wrap: nowrap;
    }
    p, li{
        color: var(--color-text-secondary);
    }
    p{
        font-size: .9rem;
        font-weight: 600;
        width: 60%;
        line-height: normal;
    }
    h3{
        font-size: 4.3rem;
        font-weight: 200;
        color: var(--color-text-primary);
    }
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    ul li{
        list-style: none;
        display: inline-block;
        text-align: center;
        font-size: 1rem;
        font-family: 'Roboto Mono', monospace;
        margin: 0 .25rem;
    }
    ul li::before{
        content: "<";
        font-size: 1rem;
    }
    ul li::after{
        content: ">";
        font-size: 1rem;
    }
    .bkgWrap{
        pointer-events: none;
    }
    .bkgWrap2{
        padding-left: 1.2rem;
        pointer-events: all;
        cursor: var(--cursor-click);
    }
    button{
        position: relative;
        z-index: 1;
        pointer-events: all;
        background-color: transparent;
        border: 1px solid var(--color-text-primary);
        color: var(--color-text-primary);
        display: inline-block;
        text-align: center;
        cursor: var(--cursor-click);
        margin-top: 1rem;
        scale: 1;
        transition: all 0.2s ease-in-out;
        font-size: .8rem;
    }
    button:hover{
        background-color: var(--color-background-inverse);
        color: var(--color-text-inverse);
        scale: 1.02;
    }
</style>

<style scoped>
    @media screen and (max-width: 1000px) {
        section{
            position: relative;
            top: 0;
            margin: 0;
            padding: 0;
            background-color: var(--color-background-secondary);
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
        }
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 1rem 0 0 0;
            width: 100%;
        }
        ul li{
            height: 1rem;
            margin: .5rem;
        }
        p{
            font-size: .9rem;
            padding: 1rem 0 1rem 0 ;
            width: 100%;
            text-align: center;
            text-wrap: wrap;
        }
        h3{
            font-size: 2.0rem;
            padding: 1rem 0 1rem 0 ;
            text-align: center;
            width: 100%;
        }
        .bkgWrap{
            width: 100%;
        }
        .bkgWrap2{
            width: 50%;
            padding: 0;
            text-align: center;
        }
    }
</style>
