<template>
    <canvas :id="id"></canvas>
</template>

<script>
    export default {
        name: "HeroAnimation",
        props: {
            id: String,
        },
        data: function() {
            return {
                canvas: null,
                ctx: null,
                frame: 0,
                agents: [],
                chunks: [],
                agentCount: 150,
                agentSize: 15,
                agentSpeedMultiplier: 5,
                mousePos: {
                    x: 0,
                    y: 0,
                },
                mouseAttraction: -0.3,
                collisionVelocityDampening: 0.075,
                chunkSplit: 10,
            }
        },
        methods: {
            init: function(){
                // add mousemove listener
                document.addEventListener('mousemove', (e) => {
                    this.mousePos.x = e.clientX;
                    this.mousePos.y = e.clientY;
                });
                
                // create agents
                this.agentCount = Math.floor(this.canvas.height / 8);
                for(let i = 0; i < this.agentCount; i++){
                    // generate random position and velocity for each agent, starting in a random position around the center of the canvas and moving in a random direction outwards
                    let radius = Math.min(this.canvas.width, this.canvas.height) / 3;
                    console.log(radius);
                    let distFromCenter = Math.random() * radius;
                    let angle = Math.random() * Math.PI * 2;
                    let x = this.canvas.width / 2 + Math.cos(angle) * distFromCenter;
                    let y = this.canvas.height / 2 + Math.sin(angle) * distFromCenter;
                    let vx = Math.cos(angle) * -2;
                    let vy = Math.sin(angle) * -2; 
                    this.agents.push({
                        x: x,
                        y: y,
                        vx: vx,
                        vy: vy,
                        size: Math.random() * 1 + .5,
                        chunkX: 0,
                        chunkY: 0,
                        closestAgent: null,
                    });
                }
                // split the agents into chunks
                for(let i = 0; i < this.chunkSplit; i++){
                    this.chunks.push([]);
                    for(let j = 0; j < this.chunkSplit; j++){
                        this.chunks[i].push([]);
                    }
                }

                // add agents to chunks
                for(let i = 0; i < this.agents.length; i++){
                    let agent = this.agents[i];
                    let chunkX = Math.floor(agent.x / (this.canvas.width / this.chunkSplit));
                    let chunkY = Math.floor(agent.y / (this.canvas.height / this.chunkSplit));
                    this.chunks[chunkX][chunkY].push(agent);
                }


                this.draw();
            },
            draw: function(){
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                for(let i = 0; i < this.agents.length; i++){
                    let agent = this.agents[i];

                    // handle movement
                    agent.x += agent.vx * this.agentSpeedMultiplier;
                    agent.y += agent.vy * this.agentSpeedMultiplier;
                    
                    // update the chunk the agent is in
                    let chunkX = Math.floor(agent.x / (this.canvas.width / this.chunkSplit));
                    let chunkY = Math.floor(agent.y / (this.canvas.height / this.chunkSplit));
                    if(agent.chunkX !== chunkX || agent.chunkY !== chunkY){
                        // remove agent from old chunk
                        let oldChunk = this.chunks[agent.chunkX] && this.chunks[agent.chunkX][agent.chunkY];
                        if(oldChunk){
                            let index = oldChunk.indexOf(agent);
                            if(index !== -1){
                                oldChunk.splice(index, 1);
                            }
                        }
                        // add agent to new chunk
                        let newChunk = this.chunks[chunkX] && this.chunks[chunkX][chunkY];
                        if(newChunk){
                            newChunk.push(agent);
                        }
                        agent.chunkX = chunkX;
                        agent.chunkY = chunkY;
                    }


                    // edge bouncing
                    if(agent.x < 0){
                        agent.x = 0;
                        agent.vx *= -1;
                    }
                    if(agent.x > this.canvas.width){
                        agent.x = this.canvas.width;
                        agent.vx *= -1;
                    }
                    if(agent.y < 0){
                        agent.y = 0;
                        agent.vy *= -1;
                    }
                    if(agent.y > this.canvas.height){
                        agent.y = this.canvas.height;
                        agent.vy *= -1;
                    }


                    // interagent interaction
                    //check the chunk the agent is in and the 8 surrounding chunks
                    let offsets = [
                        [-1, -1],
                        [0, -1],
                        [1, -1],
                        [-1, 0],
                        [0, 0],
                        [1, 0],
                        [-1, 1],
                        [0, 1],
                        [1, 1],
                    ];
                    let closestDist = Infinity;
                    chunkX = Math.floor(agent.x / (this.canvas.width / this.chunkSplit));
                    chunkY = Math.floor(agent.y / (this.canvas.height / this.chunkSplit));
                    for (let j = 0; j < offsets.length; j++) {
                        let offset = offsets[j];
                        let chunk = this.chunks[chunkX + offset[0]] && this.chunks[chunkX + offset[0]][chunkY + offset[1]];
                        if (chunk) {
                            for (let k = 0; k < chunk.length; k++) {
                                let other = chunk[k];
                                if (other !== agent) {
                                    let dx = other.x - agent.x;
                                    let dy = other.y - agent.y;
                                    let dist = Math.sqrt(dx * dx + dy * dy);
                                    if (dist < this.agentSize * 2) {
                                        let angle = Math.atan2(dy, dx);
                                        agent.vx -= Math.cos(angle) * 0.1;
                                        agent.vy -= Math.sin(angle) * 0.1;
                                        agent.vx *= 1 - this.collisionVelocityDampening;
                                        agent.vy *= 1 - this.collisionVelocityDampening;
                                    }
                                    if (dist < closestDist) {
                                        closestDist = dist;
                                        agent.closestAgent = other;
                                    }
                                }
                            }
                        }
                    }

                    let color = "#056b6b";
                    // draw agent
                    this.ctx.beginPath();
                    this.ctx.arc(agent.x, agent.y, this.agentSize * agent.size, 0, 2 * Math.PI);
                    //draw a line to the closest agent
                    if(agent.closestAgent){
                        this.ctx.moveTo(agent.x, agent.y);
                        this.ctx.lineTo(agent.closestAgent.x, agent.closestAgent.y);
                        this.ctx.strokeStyle = color;
                        this.ctx.lineWidth = 5;
                        this.ctx.stroke();
                    }
                    this.ctx.fillStyle = color;
                    this.ctx.fill();
                }
                

                // draw mouse attraction
                for(let i = 0; i < this.agents.length; i++){
                    let agent = this.agents[i];
                    let dx = this.mousePos.x - agent.x;
                    let dy = this.mousePos.y - agent.y;
                    let dist = Math.sqrt(dx * dx + dy * dy);
                    if(dist < this.agentSize * 10){
                        let angle = Math.atan2(dy, dx);
                        agent.vx += Math.cos(angle) * this.mouseAttraction;
                        agent.vy += Math.sin(angle) * this.mouseAttraction;
                    }
                }
                
                this.frame++;
                requestAnimationFrame(this.draw);
            }
        },
        mounted: function() {
            this.canvas = document.getElementById(this.id);
            this.canvas.width = window.innerWidth;
            this.canvas.height = window.innerHeight;
            this.ctx = this.canvas.getContext('2d');
            this.init();
        }
    }
</script>

<style scoped>
    canvas{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
</style>