<template>
    <section>
        <div class="backdrop" @click="$emit('open-project', null)"></div>
        <div class="background-secondary text-secondary project-case-study" @click.stop>
            <nav class="text-inverse">
                <ul class="text-inverse">
                    <li v-for="s in project.skills" :key="s">{{ s }}</li>
                </ul>
                <button @click="$emit('open-project', null)">x</button>
            </nav>
            <article>
                <h2>{{ project.caseStudy.title }}</h2>
                <p class="timeframe">{{ project.caseStudy.timeframe }}</p>
                <div class="sectionContainer">
                    <div v-for="e in project.caseStudy.elements" :key="e" :class="'section ' + e.type">
                        <img v-if="e.type === 'image'" :src="e.content"/>
                        <p class="caption" v-if="e.type === 'image'">{{ e.alt }}</p>
                        <p v-if="e.type === 'text'" v-html="pullOutLinks(e.content)"></p>
                        <h3 v-if="e.type === 'heading'">{{ e.content }}</h3>
                        <ul v-if="e.type === 'list'">
                            <li v-for="l in e.content" :key="l">{{ l }}</li>
                        </ul>
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script>
    export default { 
        name: "ProjectCaseStudy",
        components: {
            //AnotherComponent
        },
        props: {
            project: Object,
        },
        computed: {
            //myComputeFunction: function(){ return "this is cached";}
        },
        data: function() {
            return {
                // myVariableName: 'This is a function to save unique state'
            }
        },
        methods: {
            pullOutLinks(p){
                // links will be surrounded by [(link name)link url]
                let links = p.match(/\[\(.*?\)\S*]/g);
                if(links){
                    links.forEach((l) => {
                        let linkName = l.match(/\[\(.*?\)/g)[0].slice(2, -1);
                        let linkUrl = l.match(/\)\S*]/g)[0].slice(1, -1);
                        p = p.replace(l, `<a href="${linkUrl}" target="_blank" class="inline-link">${linkName}</a>`);
                    });
                }
                return p;
            },
        },
        created: function() {
            document.addEventListener('keyup', (e) => {
                if (e.key === 'Escape') {
                    this.$emit('open-project', null);
                }
            });
        }
    }
</script>

<style scoped>
    
    section{
        position: absolute;
        z-index: 10;
    }
    .backdrop{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background-color: #000;
        opacity: 0.5;
        cursor: var(--cursor-click);

    }
    .project-case-study{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;
        padding: 20px;
        width: 60vw;
        height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .project-case-study::-webkit-scrollbar {
        width: 0.5rem;
    }
    .project-case-study::-webkit-scrollbar-track {
        background: var(--color-background-secondary);
    }
    .project-case-study::-webkit-scrollbar-thumb {
        background: var(--color-text-inverse);
    }
    .project-case-study::-webkit-scrollbar-thumb:hover {
        background: var(--color-text-primary);
    }
    img{
        width: 70%;
    }
    .sectionContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
    }
    .section{
        width: 100%;
        margin: 1rem 0;
    }
    .image{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .text{
        display: block;
    }
    .heading{
        display: block;
    }
    .heading h3{
        font-size: 1.8rem;
        font-weight: 500;
    }
    .list{
        display: block;
        margin-left: 10%;
    }
    nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
    }
    nav ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    nav ul li{
        list-style: none;
        padding: 0.2rem;
        margin: 0.3rem;
        display: inline-block;
        text-align: center;
        font-family: 'Roboto Mono', monospace;

    }
    nav ul li::before{
        content: "<";
        font-size: 1.5rem;
    }
    nav ul li::after{
        content: ">";
        font-size: 1.5rem;
    }
    nav button{
        border: 1px solid var(--color-text-primary);
        border-radius: 100rem;
        padding: 0.5rem;
        margin: 0.5rem;
        width: 2rem;
        height: 2rem;
        display: inline-block;
        text-align: center;
        line-height: 0%;
        background-color: transparent;
        color: var(--color-text-primary);
        cursor: var(--cursor-click);
        transition: all 0.5s;
    }
    nav button:hover{
        background-color: var(--color-text-secondary);
        color: var(--color-text-inverse);
    }
    h2{
        font-size: 3.5rem;
        font-weight: 200;
    }
    article{
        padding: 0 4rem;
        border-left: 1px solid var(--color-text-secondary);
    }
    .timeframe{
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 2rem;
        margin-top: .5rem;
    }
    .caption{
        display: block;
        text-align: center;
        width: 70%;
        border-bottom: 1px solid var(--color-text-secondary);
        padding: 0.5rem;
    }

</style>

<!-- mobile -->
<style scoped>
    @media screen and (max-width: 1000px) {
        section{
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
        }
        
        .project-case-study{
            width: 100vw;
            height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            margin-top: 1rem;
        }
        nav ul{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            width: 80%;
        }
        nav ul li::before{
            content: "<";
            font-size: 1.5rem;
        }
        nav ul li::after{
            content: ">";
            font-size: 1.5rem;
        }
        article{
            padding: 0 1rem;
            border-left: 1px solid var(--color-text-secondary);
            width: 95%;
        }
        img{
            width: 98%;
        }
    }
</style>
