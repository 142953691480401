<template>
    <section>
        <section>
            <h1>404</h1>
            <h3><GlitchText :delay="20" :glitchCycles="20" :minTextLength="10" :passiveGlitchingEnabled="true" :txtUpdate="'#Page Not Found!'"/></h3>
            <br>
            <a href="/">Return Home</a>
        </section>
    </section>
</template>

<script>
    import GlitchText from '@/components/GlitchText.vue';
    export default { 
        name: "ErrorPage",
        components: {
            GlitchText,
        },
    }
</script>

<style scoped>
    section{
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    h1{
        font-size: 20rem;
        margin: 0;
    }
    h3{
        font-size: 5rem;
        margin: 0;
    }
    a{
        text-decoration: none;
        color: var(--color-text-primary);
        border: 1px solid var(--color-text-primary);
        padding: 1rem;
        transition: all 0.5s;
    }
    a:hover{
        background-color: var(--color-text-primary);
        color: var(--color-background-primary);
    }

    
</style>

