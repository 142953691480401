<template>
    <section>
        <div class="spacer"></div>
        <div class="footer-content">
            <hr/>
            <div class="columns">
                <div class="column">
                    <p>© {{ getCurrentYear() }} Matt Hagger</p>
                </div>
                <div class="column">
                    <p>Elsewhere</p>
                    <a href="https://github.com/Camo651" target="_blank">GitHub</a>
                    <a href="https://www.linkedin.com/in/matt-hagger/" target="_blank">LinkedIn</a>
                    <a href="https://www.youtube.com/@camo6518" target="_blank" >YouTube</a>
                    <a href="https://matthagger.itch.io/" target="_blank">Itch</a>
                </div>
                <div class="column">
                    <p>Links</p>
                    <a @click.prevent="page.setPage('Home');">Home</a>
                    <a @click.prevent="page.setPage('Blog');">Blog</a>
                    <a @click.prevent="page.setPage('lab');">Lab</a>
                    <a @click.prevent="page.setPage('About');">About</a>
                </div>
                <div class="column">
                    <a @click.prevent="scrollToTop">Back To Top</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { usePageStore } from "@/stores/pageStore.js";
    export default { 
        name: "FooterSection",
        data: function() {
            return {
                page: usePageStore(),
            };
        },
        methods: {
            getCurrentYear: function() {
                return new Date().getFullYear();
            },
            scrollToTop: function() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
    }
</script>

<style scoped>
    section{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        bottom: 0;
    }
    .footer-content{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2rem;
        color: var(--color-text-primary);
    }
    .columns{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 10px;
    }
    .column{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .column a::before{
        content: "•";
        margin-right: 0.5rem;
    }
    hr{
        width: 100%;
        border: 0;
        height: 1px;
        background-color: var(--color-text-primary);
    }
    .spacer{
        padding-bottom: 10rem;
    }
</style>

