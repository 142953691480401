<template>
    <section>
        <div class="about-section about-me">
            <div class="selfie-container">
                <img src="https://matthagger.me/images/selfie.png" alt="Matt Hagger" class="selfie">
            </div>
            <div class="about-me-text">
                <h3><GlitchText :delay="10" :glitchCycles="10" :minTextLength="3" :passiveGlitchingEnabled="true" :txtUpdate="'#A little about me'"/></h3>
                <p><span class="big-text">Hi! </span>  I'm Matt, a fullstack and game developer currently studying computer science at Worcester Polytechnic Institute.</p>
                <br>
                <p>Be it games, websites, apps, I have a passion for creating and learning anything and everything. I love to work on challenging projects that allow me to grow as a developer. I have experience in a wide range of technologies and am always looking to learn more.</p>
            </div>
        </div>
        <div class="spacer"></div>
        <div class="about-section">
            <h3 class="section-title"><GlitchText :delay="10" :glitchCycles="10" :minTextLength="3" :passiveGlitchingEnabled="true" :txtUpdate="'#Work Experience'"/></h3>
        </div>
        <div class="about-section timeline" :style="getTimelineStyle()">
            <div class="timeline-bar" v-if="!page.pageIsMobile">
                <span>{{timelineStart}}</span>
                <div class="timeline-ticks">
                    <p v-for="i in (1+getCurrentYear()-timelineStart)" :key="'ttick'+timelineStart+i">&ZeroWidthSpace;</p>
                </div>
                <span>{{getCurrentYear()}}</span>
            </div>
            <TimelineRow :begin="2022" :end="-1" :role="'Student'" :company="'Worcester Polytechnic'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2023" :end="2023" :role="'UX Intern'" :company="'AstraZeneca'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2023" :end="2023" :role="'Business Developer'" :company="'The Bake Lab'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2015" :end="-1" :role="'Technical Director'" :company="'Project Nodenium'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2022" :end="-1" :role="'Fullstack Developer'" :company="'Nodenium Software'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2019" :end="2022" :role="'Lead Developer'" :company="'Redpen Studios'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
            <TimelineRow :begin="2020" :end="-1" :role="'Tutor | Developer'" :company="'Freelance'" :link="null" :timelineLength="getTimeLineLength()" :timelineStart="timelineStart" />
        </div>
        
        <div class="spacer"></div>
        <div class="about-section">
            <h3 class="section-title"><GlitchText :delay="10" :glitchCycles="10" :minTextLength="3" :passiveGlitchingEnabled="true" :txtUpdate="'#Skills'"/></h3>
            <div class="skills-section">
                <ul class="skill-list " v-for="c in [
                    ['Languages',['C#', 'Java', 'C / C++', 'Javascript', 'PHP', 'Python', 'HTML/CSS', 'Assembly', 'SQL']],
                    ['Frameworks / Engines',['Vue', 'React', 'Angular', 'Unity3d', 'Node.js', 'Bootstrap', 'Flask', 'Nginx' ]],
                    ['Tools',['Github', 'Blender', 'Photoshop', 'After Affects', 'Illustrator', 'Trello', 'Docker', 'Solidworks']]
                    ]" :key="c[0]">
                    <h2>{{ c[0] }}</h2>
                    <br>
                    <li class="skill-item" v-for="s in c[1]" :key="s">{{ s }}</li>
                </ul>
            </div>
        </div>
        <div class="spacer"></div>
    </section>
</template>

<script>
    import GlitchText from '@/components/GlitchText.vue';
    import TimelineRow from '@/components/TimelineRow.vue';
    import { usePageStore } from "@/stores/pageStore.js";

    export default { 
        name: "AboutPage",
        components: {
            GlitchText,
            TimelineRow
        },
        data: function() {
            return {
                timelineStart: 2020,
                page: usePageStore(),
            }
        },
        methods: {
            getTimelineStyle(){
                return {
                    "grid-template-columns": "repeat("+this.getTimeLineLength()+", minmax(0, 1fr))"
                }
            },
            getCurrentYear(){
                return new Date().getFullYear()+1;
            },
            getTimeLineLength(){
                return this.getCurrentYear() - this.timelineStart;
            }
        },
    }
</script>

<style scoped>
    section{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
    .about-section{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 3%;
    }
    .about-me{
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5%;
        padding-top: 5%;
    }
    .about-me-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 40vw;
    }
    .about-me-text p{
        margin-top: 3%;
    }
    .selfie-container{
        width: 25vw;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 5%;
    }
    .selfie{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .big-text{
        font-size: 2rem;
    }
    .spacer{
        height: 5vh;
    }
    .timeline{
        display: grid;
        align-items: stretch;
        grid-gap: .3rem 0;
        width: 90%;
    }
    .timeline-bar{
        display: flex;
        flex-direction: row;
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    .timeline-ticks{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 2px solid var(--color-text-secondary);
        width: 100%;
    }
    .timeline-ticks p{
        font-size: .8rem;
        font-weight: 500;
        border-left: 2px solid var(--color-text-secondary);
        top: -4px;
        position: relative;
    }
    .timeline-bar span{
        font-size: 1.2rem;
        font-weight: 400;
        position: relative;
        top: -1.5rem;
    }
    .timeline-bar span:first-child{
        left: 3rem;
    }

    .skills-section{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
    }
    .skill-list{
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-right: 5%;

    }
    .skill-item{
        font-size: 1.2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;

    }
    .section-title{
        width: 80%;
        text-align: center;
        margin-bottom: 5%;
    }


</style>


<style scoped>
    @media screen and (max-width: 1000px) {  
        .about-section{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 3%;
        }
        .about-me{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            margin-bottom: 5%;
            padding-top: 5%;
        }
        .about-me-text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
        }
        .about-me-text p{
            margin-top: 3%;
        }
        .selfie-container{
            width: 50vw;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 5%;
        }
        .selfie{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .big-text{
            font-size: 2rem;
        }
        .spacer{
            height: 5vh;
        }
        .timeline{
            display: flex;
            align-items: center;
            grid-gap: 0.3rem 0;
            width: 95%;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        .timeline-bar{
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        .timeline-ticks{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 2px solid var(--color-text-secondary);
            width: 100%;
        }
        .timeline-ticks p{
            font-size: .8rem;
            font-weight: 500;
            border-left: 2px solid var(--color-text-secondary);
            top: -4px;
            position: relative;
        }
        .timeline-bar span{
            font-size: 1.2rem;
            font-weight: 400;
            position: relative;
            top: -1.5rem;
        }
        .timeline-bar span:first-child{
            left: 3rem;
        }

        .skills-section{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 90%;
        }
        .skill-list{
            list-style-type: none;
            margin: 0;
            padding: 0;
            margin-bottom: 5%;
        }
        .skill-list h2{
            font-size: 1.5rem;
            text-align: center;
        }
        .skill-item{
            font-size: 1.2rem;
            font-weight: 400;
            margin: 0 0 1rem 0;
            padding: 0;
            text-align: center;
        }
        h3{
            text-wrap: nowrap;
            font-size: 2.2rem;
        }
    }
</style>