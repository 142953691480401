<template>
    <a :id="role+' '+company" class="timeline-row" :href="link" target="_blank">
        <p class="timeline-info">{{ role }}<br><span>{{ company }}</span></p>
        <p class="timeline-date">{{ getDisplayDate() }}</p>
    </a>
</template>
<script>
    import { usePageStore } from "@/stores/pageStore.js";
    export default{
        "name": "TimelineRow",
        "props": {
            timelineLength: Number,
            timelineStart: Number,
            begin: Number,
            end: Number,
            role: String,
            company: String,
            link: String,
        },
        data: function(){
            return {
                rowIndex: Number,
                page: usePageStore(),
            }
        },
        "methods": {
            async getRowStyle(){
                if(this.page.pageIsMobile)
                    return;
                await new Promise(r => setTimeout(r, 10));
                let id = this.role + " " + this.company;
                let me = document.getElementById(id);
                let parent = me.parentElement;
                let index = Array.prototype.indexOf.call(parent.children, me);
                this.rowIndex = index + 1;
                
                let colSpan = Math.max(1,(this.getEndDate(false) - this.begin) + 1);
                let startOffset = this.begin - this.timelineStart + 1;
                let rad = "0";
                if(this.begin < this.timelineStart){
                    startOffset = 1;
                    colSpan = colSpan - (this.timelineStart - this.begin);
                    me.style.borderTopLeftRadius = rad;
                    me.style.borderBottomLeftRadius = rad;
                }
                if(this.end == "-1"){
                    colSpan = this.timelineLength - startOffset + 1;
                    me.style.borderTopRightRadius = rad;
                    me.style.borderBottomRightRadius = rad;
                }

                me.style.gridColumn = startOffset + " / span " + colSpan;
                me.style.gridRowStart = this.rowIndex;
                me.style.gridRowEnd = this.rowIndex + 1;

                


            },
            getDisplayDate(){
                if(this.begin == this.end)
                    return this.begin;
                return this.begin + " - " + this.getEndDate(true);
            },
            getCurrentYear(){
                return new Date().getFullYear() + 1;
            },
            getEndDate(inString){
                if(this.end == "-1"){
                    if(inString)
                        return "Present";
                    return this.getCurrentYear();
                }
                return this.end;
            },
        },
        created(){
            this.getRowStyle();
        }
    }
</script>
<style scoped>
    .timeline-row{
        height: 4vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-accent);
        padding: 20px 15px;
        border-radius: 100rem;
    }
    .timeline-info{
        font-size: .85rem;
        font-weight: 600;
        text-align: left;
        margin-left: 20px;
        line-height: 1.5rem;
        text-wrap: nowrap;
    }
    .timeline-info span{
        font-size: 1.3rem;
        font-weight: 300;
        text-wrap: nowrap;
    }
    .timeline-date{
        font-size: .9rem;
        font-weight: 300;
        text-align: right;
        margin-right: 20px;
    }


</style>

<!-- mobile style -->
<style scoped >
    @media screen and (max-width: 1000px) {
        .timeline-row{
            height: 5vh;
            padding: 10px 5px;
            border-radius: 100rem;
            width: 100%;
        }
        .timeline-info{
            font-size: .9rem;
            font-weight: 600;
            text-align: left;
            margin-left: 20px;
            line-height: 1.5rem;
            text-wrap: nowrap;
        }
        .timeline-info span{
            font-size: 1.2rem;
            font-weight: 300;
            text-wrap: nowrap;
        }
        .timeline-date{
            font-size: .8rem;
            font-weight: 300;
            text-align: right;
            margin-right: 20px;
        }
    }
</style>