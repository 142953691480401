import {defineStore} from 'pinia';

    export const usePageStore = defineStore('page',{
        state: () => {
            return {
                page: 'home',
                loaded: false,
                params: {},
                pageIsMobile: false
            }
        },
        getters: {

        },
        actions: {
            setPage(_p){
                this.setLoaded(false);
                _p = _p.toLowerCase();
                this.page = _p;
                document.title = "Matt Hagger | " + _p.charAt(0).toUpperCase() + _p.slice(1);
                if(_p === "home")
                    _p = "";
                window.scrollTo(0, 0);
                setTimeout(() => {
                    this.setLoaded(true);
                }, 500);
                this.logVisit();
            },
            setLoaded(_l){
                this.loaded = _l;
            },
            setParams(_p){
                this.params = _p;
            },
            getParam(_k){
                if(this.params[_k])
                    return this.params[_k];
                else
                    return null;
            },
            setPageScale(){
                this.pageIsMobile = window.innerWidth < 1000;
            },
            logVisit(){
                let url = "https://matthagger.me/apps/tracker"
                let message = "Visit: " + this.page;
                fetch(url, {
                    method: 'POST',
                    body: message,
                    headers:{
                      'Content-Type': 'text/plain'
                    }
                });
            }
        }
    });