<template>
    <div class="darkmode-switch" @click="toggle">
        <div class="switch" :class="{ 'switch--on': isDarkMode }">
            <div class="switch__knob">
                <Transition name="fade">
                    <span class="dlicon material-symbols-outlined" v-if="isDarkMode && animDone">dark_mode</span>
                </Transition>
                <Transition name="fade">
                    <span class="dlicon material-symbols-outlined" v-if="!isDarkMode && animDone">light_mode</span>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DarkmodeSwitch',
    data: function() {
        return {
            isDarkMode: false,
            animDone: true,
        }
    },
    methods:{
        toggle: function(){
            this.isDarkMode = !this.isDarkMode;
            this.$emit('setScheme', this.isDarkMode? 'dark' : 'light');
            localStorage.setItem("colorScheme", this.isDarkMode? 'dark' : 'light');
            this.animDone = false;
            setTimeout(() => {
                this.animDone = true;
            }, 510);
        }
    },
    created: function(){
        this.isDarkMode = localStorage.getItem("colorScheme") == 'dark';
    }
}
</script>

<style scoped>
    .darkmode-switch{
        top: 1rem;
        right: 1rem;
        z-index: 100;
        cursor: var(--cursor-click);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-background-secondary);
    }
    .switch{
        position: relative;
        width: 50px;
        height: 25px;
        border-radius: 25px;
        border: 1px solid var(--color-text-secondary);
        transition: all 0.5s ease-in-out;
        margin: 0 .8rem;
    }
    .switch__knob{
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: var(--color-text-secondary);
        transition: all 0.5s ease-in-out;
        transform: translateX(0);
        line-height: 0;
    }
    .switch--on .switch__knob{
        transform: translateX(25px);
    }
</style>
