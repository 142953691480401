<template>
    <section @mouseenter="setHoverState(true)" @mouseleave="setHoverState(false)" @click="$emit('open-project', project)">
        <div class="project-thumb">
            <div class="shade"></div>
            <img :src="'https://matthagger.me/'+project.media" v-if="project.mediaType==='image'"/>
            <video :src="'https://matthagger.me/'+project.media" muted loop webkit-playsinline playsinline v-else-if="project.mediaType==='video'" preload="auto"></video>
            <div class="pause-button">
                <span class="material-symbols-outlined" v-show="!paused" @click.stop= "setPausedState(true, project)">pause_circle</span>
                <span class="material-symbols-outlined" v-show="paused" @click.stop="setPausedState(false, project)">play_circle</span>
            </div>
            <Transition name="slide" mode="out-in">
                <InfoOverlay class="overlay" :project="project" v-if="(hover && project.inView) || page.pageIsMobile" @open-project="$emit('open-project', project)"/>
            </Transition>
        </div>
    </section>
</template>

<script>
    import InfoOverlay from './InfoOverlay.vue';
    import { usePageStore } from "@/stores/pageStore.js";

    export default { 
        name: "ProjectThumb",
        components: {
            InfoOverlay,
        },
        props: {
            project: Object,
            projectOutViewFunction: Function,
        },
        data: function() {
            return {
                hover: false,
                paused: false,
                page: usePageStore(),
            }
        },
        methods: {
            setHoverState(s){
                this.hover = s;
            },
            setPausedState(s, prj){
                this.paused = s;
                if(prj.mediaType != 'video')
                    return;
                let video = this.$el.querySelector('video');
                if(s || !prj.inView){
                    if(!video.paused)
                        video.pause();
                }else{
                    if(video.paused)
                        video.play();
                }
            }
        },
        watch: {
            project: {
                handler(newProject) {
                    this.setPausedState(this.paused, newProject);
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .project-thumb{
        width: 55vw;
        aspect-ratio: 16/9;
        cursor: var(--cursor-click);
        transition: scale 0.5s ease-in-out;
        scale: 1;
    }
    .project-thumb:hover{
        scale: 1.02;
    }
    img, video{
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
    }
    .overlay{
        margin-left: -5%;
        margin-top: 5%;
    }
    .shade{
        position: absolute;
        width: 102%;
        height: 102%;
        margin-top: -1%;
        margin-left: -1%;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(var(--color-background-primary-rgb),1) 0%, rgba(var(--color-background-primary-rgb),1) 10%, rgba(var(--color-background-primary-rgb),.2) 100%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }
    .project-thumb:hover .shade{
        opacity: 1;
    }
    .pause-button{
        position: absolute;
        bottom: -.1%;
        right: -.1%;
        z-index: 2;
        padding: .1rem;
        border-radius: 50% 0 0 0;
        transition: opacity 0.2s ease-in-out, scale 0.2s ease-in-out;
        background-color: var(--color-background-primary);
        opacity: 0;
        cursor: var(--cursor-click);
    }
    .project-thumb:hover .pause-button{
        opacity: 1;
    }
    .pause-button:hover{
        scale: 1.02;
    }
    .pause-button span{
        font-size: 2rem;
        transform: translate(3%, 3%);
        color: var(--color-text-primary);
        cursor: var(--cursor-click);
    }
    .pause-button span:hover{
        color: var(--color-accent);
    }

</style>

<style scoped>
@media screen and (max-width: 1000px) {
    .project-thumb{
        width: 95vw;
        aspect-ratio: 16/9;
        cursor: var(--cursor-click);
        transition: scale 0.5s ease-in-out;
        scale: 1;
    }
    .project-thumb:hover{
        scale: 1;
    }
    .shade{
        display: none;
    }
    .overlay{
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 7%;
    }
    .pause-button{
        opacity: 1;
        background-color: transparent;
        top: 0;
        right: 0;
    }
    .pause-button:hover{
        scale: 1;
    }
}
</style>