<template>
    <div class="bkgCover" @click.prevent @scroll.prevent @wheel.prevent @touchmove.prevent @touchstart.prevent @touchend.prevent>
        <div class="loading">
            <div class="loading__icon"></div>
            <div class="loading__text">Loading</div>
        </div>
    </div>
</template>
<script>
    export default{
        name: "LoadingPage",
    }
</script>
<style scoped>
    .bkgCover{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--color-background-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .loading{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--color-text-primary);
        font-size: 2rem;
        font-family: 'Roboto Mono', monospace;
    }
    .loading__icon{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 1px solid var(--color-accent);
        background-color: transparent;
        animation: bounce 1s infinite, flip 3s infinite;
        animation-delay: 0, .5;
        animation-composition: accumulate;
    }
    .loading__text{
        margin-top: 2rem;
    }

    @keyframes bounce{
        0%{
            scale: 1;
        }
        50%{
            scale: 1.2;
        }
        100%{
            scale: 1;
        }
    }
    @keyframes flip{
        0%{
            scale: 1;
        }
        70%{
            scale: 1.5;
        }
        75%{
            scale: .1;
        }
        100%{
            scale: 1;
        }
    }

</style>