<template>
    <section class="main-page background-primary text-primary">
        <LoadingPage v-if="!page.loaded"/>
        <main>
            <HomePage v-if="page.page=='home'"/>
            <BlogPage v-else-if="page.page=='blog'"/>
            <ArchivePage v-else-if="page.page=='lab'"/>
            <AboutPage v-else-if="page.page=='about'"/>
            <ContactPage v-else-if="page.page=='contact'"/>
            <ErrorPage v-else/>
        </main>
        <FooterSection/>
        <NavigationSection @setScheme="setColorScheme($event)"/>

    </section>
</template>

<script>
    import NavigationSection from "./components/NavigationSection.vue";
    import HomePage from "./pages/HomePage.vue";
    import BlogPage from "./pages/BlogPage.vue";
    import ArchivePage from "./pages/ArchivePage.vue";
    import AboutPage from "./pages/AboutPage.vue";
    import ErrorPage from "./pages/ErrorPage.vue";
    import FooterSection from "./components/FooterSection.vue";
    import LoadingPage from "./pages/LoadingPage.vue";
    import ContactPage from "./pages/ContactPage.vue";

    import { usePageStore } from "./stores/pageStore.js";

    export default { 
        name: "App",
        components: {
            NavigationSection,
            HomePage,
            BlogPage,
            ArchivePage,
            AboutPage,
            ErrorPage,
            FooterSection,
            LoadingPage,
            ContactPage,
        },
        data: function() {
            return {
                page: usePageStore(),
                schemes:{
                    light: {
                        "bkg1": "#f2faff",
                        "bkg2": "#f9fcfc",
                        "txt1": "#040316",
                        "txt2": "#2A3737",
                        "acc": "#60beb9",
                        "anc": "#056b6b",
                        "img": 1,
                        cursors: {
                            main: "https://matthagger.me/cursors/cursor_main_light.png",
                            click: "https://matthagger.me/cursors/cursor_click_light.png"
                        }
                    },
                    dark: {
                        "bkg1": "#161C1C",
                        "bkg2": "#121c1c",
                        "txt1": "#FBFAFA",
                        "txt2": "#D2DBDB",
                        "acc": "#60beb9",
                        "anc": "#48bcbc",
                        "img": 0,
                        cursors: {
                            main: "https://matthagger.me/cursors/cursor_main_dark.png",
                            click: "https://matthagger.me/cursors/cursor_click_dark.png"
                        }
                    }
                }
            }
        },
        methods: {
            setColorScheme(s){
                let scheme = this.schemes[s];
                this.setProp('--color-background-primary', scheme.bkg1);
                this.setProp('--color-background-secondary', scheme.bkg2);
                this.setProp('--color-background-inverse', scheme.txt1);
                this.setProp('--color-text-primary', scheme.txt1);
                this.setProp('--color-text-secondary', scheme.txt2);
                this.setProp('--color-text-inverse', scheme.bkg1);
                this.setProp('--color-accent', scheme.acc);
                this.setProp('--color-text-anchor', scheme.anc);
                this.setProp('--color-image-filter', "invert("+scheme.img+")");

                this.setProp('--color-background-primary-rgb', this.hexToRgb(scheme.bkg1));
                this.setProp('--color-background-secondary-rgb', this.hexToRgb(scheme.bkg2));
                this.setProp('--color-background-inverse-rgb', this.hexToRgb(scheme.txt1));
                this.setProp('--color-text-primary-rgb', this.hexToRgb(scheme.txt1));
                this.setProp('--color-text-secondary-rgb', this.hexToRgb(scheme.txt2));
                this.setProp('--color-text-inverse-rgb', this.hexToRgb(scheme.bkg1));
                this.setProp('--color-accent-rgb', this.hexToRgb(scheme.acc));
                this.setProp('--color-text-anchor-rgb', this.hexToRgb(scheme.anc));
                
                // this.setProp('--cursor-main', "url("+scheme.cursors.main+") 8 8, auto");
                // this.setProp('--cursor-click', "url("+scheme.cursors.click+") 8 8, auto");
            },
            setProp(prop, setting){
                document.documentElement.style.setProperty(prop, setting);
            },
            hexToRgb(h){
                let r = parseInt(h.substring(1,3), 16);
                let g = parseInt(h.substring(3,5), 16);
                let b = parseInt(h.substring(5,7), 16);
                return r+","+g+","+b;
            },
        },
        created: function() {            
            // cache all cursor type images to prevent lag
            let cursors = Object.values(this.schemes.light.cursors).concat(Object.values(this.schemes.dark.cursors));
            for(let i = 0; i < cursors.length; i++){
                let img = new Image();
                img.src = cursors[i];
            }

            //listen for page scale changes
            window.addEventListener("resize", this.page.setPageScale);
            this.page.setPageScale();

            // set the scheme and page
            let scheme = localStorage.getItem("colorScheme");
            scheme = scheme == null? "light" : scheme;
            this.setColorScheme(scheme);
            // this.page.setPage("home");

            // grab the path from the url
            let path = window.location.pathname;
            path = path == "/"? "home" : path.substring(1);
            if(path == "dev"){
                path = "contact";
            }
            // clear the path
            window.history.pushState("", "", "/");
            console.log(path);
            this.page.setPage(path);

            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }
        },
    }
</script>

<!-- Global styles -->
<style>

    :root{
        --color-background-primary: #0f0f0f;
        --color-background-secondary: #222222;
        --color-background-inverse: #f8f8f8;
        --color-text-primary: #f5f5f5;
        --color-text-secondary: #dbdbdb;
        --color-text-inverse: #111111;
        --color-accent: #fc5089;
        --color-text-anchor: #6150fc;
        --color-image-filter: invert(0);

        --cursor-main: auto;
        --cursor-click: pointer;

    }
    *{
        cursor: var(--cursor-main);
        transition: color .5s ease-in-out;
        transition: background-color .5s ease-in-out;
    }
    .background-primary{
        background-color: var(--color-background-primary);
    }
    .background-secondary{
        background-color: var(--color-background-secondary);
    }
    .background-inverse{
        background-color: var(--color-background-inverse);
    }
    .text-primary{
        color: var(--color-text-primary);
    }
    .text-secondary{
        color: var(--color-text-secondary);
    }
    .text-inverse{
        color: var(--color-text-inverse);
    }
    .text-accent{
        color: var(--color-accent);
    }
    
    html{
        margin: 0;
        padding: 0;
        width: 100vw;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    body{
        background-color: var(--color-background-primary);
        margin: 0;
        padding: 0;
        width: 100vw;
        overflow-x: hidden;
    }
    html::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    a{
        color: var(--color-text-secondary);
        text-decoration: none;
    }
    a:hover{
        color: var(--color-text-anchor);
        cursor: var(--cursor-pointer);
    }
    button:hover{
        cursor: var(--cursor-pointer);
    }
    .inline-link{
        color: var(--color-text-anchor);
        text-decoration: none;
        border-bottom: 1px solid var(--color-text-primary);
        pointer-events: all;
        /* why wont the link open when clicked? */
        /* A: because the link is a child of a button, which is disabled */
    }
    .inline-link:hover{
        color: var(--color-text-primary);
        border-bottom: 1px solid var(--color-text-primary);
    }

    /* transition classes */
    .slide-enter-from, .slide-leave-to{
        opacity: 0;
        transform: translateX(-5vw);
    }
    .slide-enter-active, .slide-leave-active{
        transition: opacity 0.5s, transform 0.5s;
    }
    .slide-enter-to, .slide-leave-from{
        opacity: 1;
        transform: translateX(0);
    }
    .fade-enter-active, .fade-leave-active{
        transition: opacity 0.5s;
    }
    .fade-enter-from, .fade-leave-to{
        opacity: 0;
    }
    .fade-enter-to, .fade-leave-from{
        opacity: 1;
    }
    .longfade-enter-active, .longfade-leave-active{
        transition: opacity 2s;
    }
    .longfade-enter-from, .longfade-leave-to{
        opacity: 0;
    }
    .longfade-enter-to, .longfade-leave-from{
        opacity: 1;
    }
    
</style>

<!-- Desktop styles -->
<style>
    @media screen and (min-width: 1000px) {
        h1, h2, h3, h4, h5, h6, p, ul, li, a, button, input, textarea{
            margin: 0;
            padding: 0;
        }
        h1, h2, h3, h4, h5, h6{
            font-family: 'Raleway', sans-serif;
        }
        p, ul, li, a, button, input, textarea, span{
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 1rem;
        }
        h1, h2, h3, h4, h5, h6, p, ul, li{
            color: var(--color-text-primary);
        }
        h1{
            font-size: 2rem;
            font-weight: 200;
        }
        h2{
            font-size: 1.5rem;
            font-weight: 500;
        }
        h3{
            font-size: 3rem;
            font-weight: 200;
        }
        h4{
            font-size: 1.1rem;
            font-weight: 500;
            padding: 0.5rem;
        }
        .main-page{
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        main{
            scroll-behavior: smooth;
            width: 80vw;
        }

        .hero{
            position: relative;
            width: 100%;
            height: 80vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
        }
        .hero-small{
            height: 40vh;
        }
        .hero h1{
            font-size: 5rem;
            margin: 0;

        }
        .hero h2{
            font-size: 2rem;
            margin: 0;
        }
    }
</style>

<!-- Mobile styles -->
<style>
    @media screen and (max-width: 1000px) {
        h1, h2, h3, h4, h5, h6, p, ul, li, a, button, input, textarea{
            margin: 0;
            padding: 0;
        }
        h1, h2, h3, h4, h5, h6{
            font-family: 'Raleway', sans-serif;
        }
        p, ul, li, a, button, input, textarea, span{
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 1rem;
        }
        h1, h2, h3, h4, h5, h6, p, ul, li{
            color: var(--color-text-primary);
        }
        h1{
            font-size: 2rem;
            font-weight: 200;
        }
        h2{
            font-size: 1.5rem;
            font-weight: 500;
        }
        h3{
            font-size: 3rem;
            font-weight: 200;
        }
        h4{
            font-size: 1.1rem;
            font-weight: 500;
            padding: 0.5rem;
        }
        .main-page{
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        main{
            scroll-behavior: smooth;
            width: 100vw;
        }

        .hero{
            position: relative;
            width: 100%;
            height: 80vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
        }
        .hero-small{
            height: 40vh;
        }
        .hero h1{
            font-size: 5rem;
            margin: 0;
            padding: 2rem 4rem;
            text-wrap: wrap;
            text-align: center;
        }
        .hero h2{
            font-size: 2rem;
            margin: 0;
        }
    }
</style>