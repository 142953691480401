<template>
    <section>
        <section class="hero hero-small">
            <h3><GlitchText :delay="10" :glitchCycles="10" :minTextLength="5" :passiveGlitchingEnabled="true" :txtUpdate="'#Prototype Lab'"/></h3>
            <!-- Some sort of subtitle to show this is where non complete projects go but dont make them sound bad.. -->
            <h4>Experiments & Unfinished Projects</h4>
        </section>
        <ProjectsList project-flag="archive" :pageOpen="true"/>
    </section>
</template>

<script>
    import ProjectsList from '@/components/ProjectsList.vue';
    import GlitchText from '@/components/GlitchText.vue';

    export default { 
        name: "ArchivePage",
        components: {
            ProjectsList,
            GlitchText,
        },
    }
</script>

<style scoped>
    h3{
        text-wrap: nowrap;
    }
</style>

