<template>
    <section>
        <section class="hero hero-small">
            <h3><GlitchText :delay="20" :glitchCycles="20" :minTextLength="10" :passiveGlitchingEnabled="true" :txtUpdate="'#Blog'"/></h3>
            <h4>Coming Soon...</h4>
        </section>
    </section>
</template>

<script>
    import GlitchText from '@/components/GlitchText.vue';
    export default { 
        name: "BlogPage",
        components: {
            GlitchText,
        },
        props: {
            //myPropName: String
        },
        computed: {
            //myComputeFunction: function(){ return "this is cached";}
        },
        data: function() {
            return {
                // myVariableName: 'This is a function to save unique state'
            }
        },
        methods: {
            // myFunctionName() {return "this is not cached";}
        },
        created: function() {
            // this.myFunctionName();
        }
    }
</script>

<style scoped>

</style>

