<template>
    <section>
        <Transition name="longfade">
            <section class="background-cover" v-if="!buttonClicked">
                <HeroAnimation id="heroAnimation"/>
                <div class="center-block"></div>
            </section>
        </Transition>
        <section class="hero" id="homehero">
            <h1><GlitchText :ref="'glitchTextTitle'" :delay="10" :glitchCycles="5" :minTextLength="10" :effectDoneCallback="titleEffectDone" :passiveGlitchingEnabled="false" :txtUpdate="title"/></h1>
            <h2><GlitchText :ref="'glitchTextSubtitle'" :delay="2" :glitchCycles="4" :minTextLength="20" :passiveGlitchingEnabled="true" :txtUpdate="subtitle" :effectDoneCallback="subtitleEffectDone"/></h2>
            <!-- <Transition name="fade"> -->
                <button class="button" @click="clickButton" v-if="buttonVisible && !buttonClicked">View Projects</button>
                <button class="button" @click="scrollDown" v-else-if="buttonClicked">V</button>
            <!-- </Transition> -->
        </section>
        <ProjectsList project-flag="main" :pageOpen="true"/>
    </section>
</template>

<script>
    import ProjectsList from "@/components/ProjectsList.vue";    
    import GlitchText from "@/components/GlitchText.vue";
    import HeroAnimation from "@/components/HeroAnimation.vue";
    export default { 
        name: "HomePage",
        components: {
            ProjectsList,
            GlitchText,
            HeroAnimation,
        },
        props: {
            
        },
        computed: {
            
        },
        data: function() {
            return {
                title: "",
                subtitle: "",
                buttonVisible: false,
                buttonClicked: false,
            }
        },
        methods: {
            titleEffectDone(){
                this.subtitle = "Full Stack Developer / Web Designer";
            },
            subtitleEffectDone(){
                this.buttonVisible = true;
            },
            clickButton(){
                this.buttonClicked = true;
                document.body.style.overflow = "auto";
                setTimeout(() => {
                    document.getElementById("homehero").style.zIndex = 'auto';
                }, 2000);
            },
            scrollDown(){
                window.scrollTo({
                    top: window.innerHeight,
                    behavior: 'smooth'
                });
            }
        },
        created: function() {
            setTimeout(() => {
                this.title = "Matt Hagger";
            }, 1000);
            document.body.style.overflow = "hidden";
        }
    }
</script>

<style scoped>
    h1{
        background-color: var(--color-accent);
        color: var(--color-background-secondary);
        padding: 6rem 12rem;
        margin-bottom: 4rem !important;
        filter: drop-shadow(.5rem .5rem 0rem rgba(var(--color-accent-rgb),0.5));
        text-wrap: nowrap;
    }
    h2{
        font-size: 2rem;
        text-align: center;
    }
    .hero{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 96vh;
        width: 100%;
        z-index: 20;
    }

    .background-cover{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--color-background-primary);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 10;
    }

    .button{
        background-color: transparent;
        color: var(--color-text-primary);
        padding: 1rem 2rem;
        border-radius: 1rem;
        border: 1px solid var(--color-text-primary);
        font-size: 1rem;
        font-weight: 500;
        cursor: var(--cursor-click);
        margin-top: 3rem;
    }

    .button:hover{
        background-color: var(--color-background-inverse);
        color: var(--color-text-inverse);
        transform: scale(1.02);
        cursor: var(--cursor-pointer);
    }


    .center-block{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        z-index: 22;
        background: linear-gradient(90deg, transparent 0%, rgba(var(--color-background-primary-rgb),.9) 30%, rgba(var(--color-background-primary-rgb),.9) 70%, transparent 100%);
    }

</style>

