<template>
    <section>
        <section class="hero hero-small">
            <h3><GlitchText :delay="20" :glitchCycles="20" :minTextLength="10" :passiveGlitchingEnabled="true" :txtUpdate="'#Custom Portfolios and Websites'"/></h3>
            <h2>Making a portfolio can be a daunting task.</h2>
            <h4>With full service design, development, and hosting, I can help you get your work online and in front of potential employers.</h4>
            <h4>With a your very own URL on top of your resume, you can stand out from the crowd and show off your work in a professional manner.</h4>
            
            <h4>Get in touch with me to get started on your custom portfolio today.</h4>
            <div class="button-container">
                <a href="mailto:matthagger64@gmail.com" class="button" target="_blank">matthagger64@gmail.com</a>
                <a href="tel:301-385-7558" class="button" target="_blank">301-385-7558</a>

            </div>
        </section>
    </section>
</template>

<script>
    import GlitchText from '@/components/GlitchText.vue';
    export default { 
        name: "ContactPage",
        components: {
            GlitchText,
        },
        props: {
            //myPropName: String
        },
        computed: {
            //myComputeFunction: function(){ return "this is cached";}
        },
        data: function() {
            return {
                // myVariableName: 'This is a function to save unique state'
            }
        },
        methods: {
            // myFunctionName() {return "this is not cached";}
        },
        created: function() {
            // this.myFunctionName();
        }
    }
</script>

<style scoped>
    .hero {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
    }
    h3 {
        margin-top: 10rem;
        margin-bottom: 2rem;
        text-align: center;
    }
    h4, h2{
        margin: 2rem;
        text-align: center;
        padding: 0;
        width: 50%;
    }

    .button {
        margin: 1rem;
        padding: 1rem;
        border: 2px solid var(--color-text-primary);
        text-decoration: none;
        scale: 1;
    }
    .button:hover {
        background-color: var(--color-text-primary);
        color: var(--color-background-primary);
        scale: 1.1;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>

