<template>
    <section v-if="!page.pageIsMobile">
        <nav>
            <a :class="'nav-link '+(page.page==='home'?'page':'')" @click.stop="this.setPage('Home')">Projects</a>
            <a :class="'nav-link '+(page.page==='about'?'page':'')" @click.stop="this.setPage('About')">About</a>
            <a :class="'nav-link '+(page.page==='blog'?'page':'')" @click.stop="this.setPage('Blog')">Blog</a>
            <a :class="'nav-link '+(page.page==='lab'?'page':'')" @click.stop="this.setPage('Lab')">Lab</a>
            <a :class="'nav-link '+(page.page==='contact'?'page':'')" @click.stop="this.setPage('Contact')">Services</a>
        </nav>
        <div class="externalLinks">
            <div class="socialLinks">
                <a target="_blank" class="link" href="mailto:contact@matthagger.me">
                    <img class="icon" id="rotIcon" src="https://matthagger.me/icons/emailIcon.png">
                    <p class="subtitle">Email</p>
                </a>
                <a target="_blank" class="link" href="https://www.linkedin.com/in/matt-hagger/">
                    <img class="icon" id="rotIcon" src="https://matthagger.me/icons/linkedIcon.png">
                    <p class="subtitle">LinkedIn</p>
                </a>
                <a target="_blank" class="link" href="https://github.com/Camo651">
                    <img class="icon" id="rotIcon" src="https://matthagger.me/icons/gitIcon.png">
                    <p class="subtitle">Github</p>
                </a>
            </div>
            <button class="dl-resume" @click="downloadResume">Resume</button>
            <DarkmodeSwitch @setScheme="$emit('setScheme', $event)"/>
        </div>
    </section>
    <section v-else class="mobile-nav">
        <Transition name="slide">
        <div class="mobile-content" v-if="menuOpen">
            <nav>
                <a :class="'nav-link '+(page.page==='home'?'page':'')" @click.stop="this.setPage('Home')">Projects</a>
                <a :class="'nav-link '+(page.page==='about'?'page':'')" @click.stop="this.setPage('About')">About</a>
                <a :class="'nav-link '+(page.page==='blog'?'page':'')" @click.stop="this.setPage('Blog')">Blog</a>
                <a :class="'nav-link '+(page.page==='lab'?'page':'')" @click.stop="this.setPage('Lab')">Lab</a>
                <a :class="'nav-link '+(page.page==='contact'?'page':'')" @click.stop="this.setPage('Contact')">Services</a>
            </nav>
            <div class="externalLinks">
                <div class="socialLinks">
                    <a target="_blank" class="link" href="mailto:contact@matthagger.me">
                        <img class="icon" id="rotIcon" src="https://matthagger.me/icons/emailIcon.png">
                        <p class="subtitle">Email</p>
                    </a>
                    <a target="_blank" class="link" href="https://www.linkedin.com/in/matt-hagger/">
                        <img class="icon" id="rotIcon" src="https://matthagger.me/icons/linkedIcon.png">
                        <p class="subtitle">LinkedIn</p>
                    </a>
                    <a target="_blank" class="link" href="https://github.com/Camo651">
                        <img class="icon" id="rotIcon" src="https://matthagger.me/icons/gitIcon.png">
                        <p class="subtitle">Github</p>
                    </a>
                </div>
                <button class="dl-resume" @click="downloadResume">Resume</button>
                <DarkmodeSwitch @setScheme="$emit('setScheme', $event)"/>
            </div>
        </div>
        </Transition>
        <div class="hamburger-menu" @click.stop="menuOpen = !menuOpen">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </section>
</template>

<script>
    import DarkmodeSwitch from './DarkmodeSwitch.vue';
    import { usePageStore } from "@/stores/pageStore.js";

    export default {
    name: "NavigationSection",
    data: function () {
        return {
            page: usePageStore(),
            menuOpen: false,
        };
    },
    methods: {
        downloadResume: function () {
            window.open("https://matthagger.me/files/Matt_Hagger_Resume.pdf", "_blank");
        },
        setPage(p) {
            this.menuOpen = false;
            this.page.setPage(p);
        },
    },
    components: { DarkmodeSwitch }
}
</script>

<!-- Desktop -->
<style scoped>
    section{
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        right: 0;
        pointer-events: none;
    }
    nav{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10vw;
        pointer-events: all;
    }
    .nav-link{
        padding: 1rem;
        text-decoration: none;
        font-size: 1.5rem;
        transition: all 0.5s;
        cursor: var(--cursor-click);
    }
    .nav-link:hover{
        transform: scale(1.02);
    }
    .socialLinks{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 90%;
        flex-wrap: nowrap;
        cursor: var(--cursor-click);
    }
    .link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: all 0.5s;
        cursor: var(--cursor-click);
    }
    .link:hover{
        transform: scale(1.12);
    }
    .icon{
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.5rem;
        filter: var(--color-image-filter);
        cursor: var(--cursor-click);
    }
    .subtitle{
        font-size: .6rem;
        font-weight: 500;
    }
    .dl-resume{
        margin: 1rem;
        padding: .75rem;
        border: 1px solid var(--color-text-secondary);
        border-radius: 0.5rem;
        background-color: transparent;
        color: var(--color-text-primary);
        font-size: .8rem;
        transition: all 0.5s;
        width: 60%;
        cursor: var(--cursor-click);
    }
    .dl-resume:hover{
        transform: scale(1.02);
        cursor: var(--cursor-pointer);
        color: var(--color-text-inverse);
        background-color: var(--color-background-inverse);
    }
    .externalLinks{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 10vw;
        flex-wrap: nowrap;
        position: absolute;
        top: 5px;
        right: 5px;
        pointer-events: all;
    }
    .page{
        color: var(--color-text-anchor);
        font-weight: 500;
    }
    .page::before{
        content: ">> ";
        font-size: 1.5rem;
        text-wrap: nowrap;
    }
</style>

<!-- Mobile -->
<style scoped>
    @media screen and (max-width: 1000px) {
        .mobile-nav{
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            pointer-events: none;
            display: flex;
            flex-direction: column;
        }
        .hamburger-menu{
            position: absolute;
            top: 5px;
            left: 5px;
            width: 2rem;
            height: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            pointer-events: all;
            background-color: var(--color-background-primary);
            border-radius: 0.5rem;
            padding: 0.5rem;
        }
        .hamburger-menu .line{
            width: 100%;
            height: 2px;
            background-color: var(--color-text-primary);
            transition: all 0.5s;
        }
        .hamburger-menu .line:nth-child(2){
            transform: rotate(0deg);
        }
        .mobile-content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            pointer-events: all;
            background-color: var(--color-background-secondary);
        }
        .mobile-content nav{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: 2rem;
        }
        .mobile-content .externalLinks{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            flex-wrap: nowrap;
            margin-top: 30%;
        }
        .socialLinks{
            justify-content: space-evenly;
        }
    }
</style>

