<template>
    {{ text }}
</template>
<script>
    import { usePageStore } from "@/stores/pageStore.js";
    export default{
        name: "GlitchText",
        data: function(){
            return {
                text: "",
                page: usePageStore(),
            }
        },
        props: {
            delay: Number,
            glitchCycles: Number,
            minTextLength: Number,
            effectDoneCallback: Function,
            passiveGlitchingEnabled: Boolean,
            txtUpdate: String,
        },
        watch: {
            txtUpdate: function(newVal){
                this.setText(newVal);
            }
        },
        methods: {
            async setText(text2){
                let text = this.text;
                let insertables = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_+-=,./<>?;':\"[]{}\\|`~";
                let newText = "";
                let maxChars = Math.max(text.length, text2.length, (this.minTextLength!=null?this.minTextLength:0));
                let minChars = Math.min(text.length, text2.length, (this.minTextLength!=null?this.minTextLength:10000));
                let glitchCharCount = ((maxChars - minChars)/2) + minChars;

                // slowly one by one replace each character in the current text with a random character from the insertables string
                for(let i = 0; i < maxChars; i++){
                    if(i < glitchCharCount)
                        newText += insertables[Math.floor(Math.random() * insertables.length)];
                    else
                        newText += " ";
                    this.text = newText;
                    await new Promise(r => setTimeout(r, this.delay));
                }

                // randomly replace characters that are not whitespace in the current text with a random character from the insertables string for glitchCycles number of times
                for(let i = 0; i < this.glitchCycles; i++){
                    let glitchIndex = Math.floor(Math.random() * maxChars);
                    while(newText[glitchIndex] == " ")
                        glitchIndex = Math.floor(Math.random() * maxChars);
                    newText = newText.substring(0, glitchIndex) + insertables[Math.floor(Math.random() * insertables.length)] + newText.substring(glitchIndex + 1);
                    this.text = newText;
                    await new Promise(r => setTimeout(r, this.delay));
                }
                
                // slowly one by one replace each character in the current text with the corresponding character from the new text. if the text2 is longer than the current text, whitespace fill it
                for(let i = 0; i < maxChars; i++){
                    newText = newText.substring(0, i) + ((i < text2.length)?text2[i]:" ") + newText.substring(i + 1);
                    this.text = newText;
                    await new Promise(r => setTimeout(r, this.delay));
                }

                // call the callback function if it exists
                if(this.effectDoneCallback != null)
                    this.effectDoneCallback();
                if(this.passiveGlitchingEnabled)
                    this.passiveGlitching();
            },
            async passiveGlitching(){
                if(this.page.pageIsMobile)
                    return;
                let insertables = "1234567890!@#$%^&*()_+-=,./<>?;':\"[]{}\\|`~                ";
                let maxChars = this.text.length / 3;
                let minChars = this.text.length / 5;
                for(;;){
                    let text = this.text;
                    let newText = "";
                    if(Math.random() < 0.4){
                        // one char
                        let randIndex = Math.floor(Math.random() * text.length);
                        newText = text.substring(0, randIndex) + insertables[Math.floor(Math.random() * insertables.length)] + text.substring(randIndex + 1);
                        this.text = newText;
                        await new Promise(r => setTimeout(r, Math.random() * 1000));
                    }else{
                        // multiple consecutive chars
                        let charCount = Math.floor(Math.random() * maxChars) + minChars;
                        let randIndex = Math.floor(Math.random() * (text.length - charCount));
                        // replace each character in the current text with a random character from the insertables string with a small delay between each
                        newText = text;
                        for(let i = 0; i < charCount; i++){
                            newText = newText.substring(0, randIndex + i) + insertables[Math.floor(Math.random() * insertables.length)] + text.substring(randIndex + i + 1);
                            this.text = newText;
                            await new Promise(r => setTimeout(r, 100));
                        }
                        await new Promise(r => setTimeout(r, Math.random() * 1000));
                        // same effect but in reverse
                        for(let i = 0; i < charCount; i++){
                            newText = text.substring(0, randIndex + i) + newText.substring(randIndex + i);
                            this.text = newText;
                            await new Promise(r => setTimeout(r, 50));
                        }

                    }
                    
                    this.text = text;
                    await new Promise(r => setTimeout(r, (Math.random() * 2000) + 2000));
                }
            }
        },
        created: async function(){
            if(this.txtUpdate.charAt(0) == "#"){
                await new Promise(r => setTimeout(r, 800));
                this.setText(this.txtUpdate.substring(1));
            }
        }
    }
</script>