<template>
    <section>

        <ul>
            <li v-for="p in projectList" :key="p.name">
                <ProjectThumb :project="p" @open-project="openProject"/>
            </li>
        </ul>
        <Transition name="fade">
            <ProjectCaseStudy :project="openProjectCase" v-if="openProjectCase != null" @open-project="openProject"/>
        </Transition>
    </section>
</template>

<script>
    import ProjectThumb from "../components/ProjectThumb.vue";
    import ProjectCaseStudy from "../components/ProjectCaseStudy.vue";
    import { usePageStore } from "../stores/pageStore.js";

    export default { 
        name: "ProjectsList",
        components: {
            ProjectThumb,
            ProjectCaseStudy,
        },
        props: {
            projectFlag: String,
        },
        data: function() {
            return {
                page: usePageStore(),
                projectList: [],
                openProjectCase: Object,
                saveDocTitle: String,
            }
        },
        methods: {
            getMyProjects(){
                let xhr = new XMLHttpRequest();
                xhr.open("GET", "/projects/projects.json", false);
                xhr.send();
                if(xhr.status === 200){
                    return JSON.parse(xhr.responseText)[this.projectFlag].filter(p => !p.hide);
                }
                return [];
            },
            openProject(p){
                if(p!=null && p.caseStudy==null){return;}
                this.openProjectCase = p;
                if(p != null){
                    document.body.style.overflow = "hidden";
                    this.saveDocTitle = document.title;
                    document.title = p.name;
                }else{
                    document.body.style.overflow = "scroll";
                    document.title = this.saveDocTitle;
                }
            },
            getMostVisibleProject(){
                let mostVisible = -1;
                let minDistanceFromCenter = Infinity;
                let projects = document.querySelectorAll(".project-thumb");
                for(let i = 0; i < projects.length; i++){
                    let project = projects[i];
                    let rect = project.getBoundingClientRect();
                    let distanceFromCenter = Math.abs(rect.top + rect.height/2 - window.innerHeight/2);
                    if(distanceFromCenter < minDistanceFromCenter){
                        minDistanceFromCenter = distanceFromCenter;
                        mostVisible = i;
                    }
                }
                if(minDistanceFromCenter > window.innerHeight/2)
                    return -1;
                return mostVisible;
            },
            setProjectInView(){
                for(let i = 0; i < this.projectList.length; i++){
                    this.projectList[i].inView = false;                    
                }
                let mostVisible = this.getMostVisibleProject();
                if(mostVisible != -1){
                    if(mostVisible < this.projectList.length)
                        this.projectList[mostVisible].inView = true;
                }
            }
        },
        created: function() {
            this.projectList = this.getMyProjects();
            this.openProjectCase = null;
            this.projectList[0].inView = false;
            window.addEventListener('scroll', this.setProjectInView);
        }
    }
</script>

<style scoped>
    ul{
        list-style: none;
        padding: 0;
    }
    li{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 20vh;

    }
</style>

